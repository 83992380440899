/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, Button } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-15qaqbz js-anim  --anim7 --anim-s5 bg--bottom --full" anim={"7"} name={"wstęp"} animS={"5"} border={null} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pt--12" anim={"6"} animS={"5"} style={{"maxWidth":1080}}>
              
              <Title className="title-box fs--102 w--300 lh--12" content={"<span style='color: white'>Martyna & Andrzej</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style='color: white'>27—7—2021, Dolina Pięciu Stawów w Tatrach</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--50" name={"informacje"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center w--300" content={"Uroczystość"}>
              </Title>

              <Text className="text-box" content={"14:00<br>"}>
              </Text>

              <Text className="text-box" content={"Kościół pw. św. Maksymiliana Kolbe"}>
              </Text>

              <Text className="text-box" content={"Pańska 18 12"}>
              </Text>

              <Text className="text-box" content={"Dolina Pięciu Stawów w Tatrach"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=860x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--25" name={"informacje-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=860x_.png"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=860x_.png 860w, https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=1400x_.png 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center w--300" content={"Recepcja"}>
              </Title>

              <Text className="text-box" content={"16:30<br>"}>
              </Text>

              <Text className="text-box" content={"Hotel GRAND<br>"}>
              </Text>

              <Text className="text-box" content={"Pańska 18 12"}>
              </Text>

              <Text className="text-box" content={"Dolina Pięciu Stawów w Tatrach"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-141tgzy --center --parallax pb--80 pt--80" name={"informacje-3"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4cd11cefb48744d78ffadc3b7730d41f_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4cd11cefb48744d78ffadc3b7730d41f_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4cd11cefb48744d78ffadc3b7730d41f_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4cd11cefb48744d78ffadc3b7730d41f_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4cd11cefb48744d78ffadc3b7730d41f_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4cd11cefb48744d78ffadc3b7730d41f_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4cd11cefb48744d78ffadc3b7730d41f_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4cd11cefb48744d78ffadc3b7730d41f_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pb--40 pt--40" anim={"6"} animS={"5"}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--62" innerClassName="mt--10 mb--10" href={"/pl/galeria"} content={"Galeria"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--62" innerClassName="mt--10 mb--10" href={"/pl/prezenty-ślubne"} content={"Prezenty ślubne"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--62" innerClassName="mt--10 mb--10" href={"potwierdź-udział"} content={"Potwierdź udział"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"informacje-4"} style={{"backgroundColor":"var(--color-blend--05)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--center w--300" content={"<span style=\"color: var(--color-dominant);\">Jak to wszystko się zaczęło</span>"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/pl/informacje"} content={"Więcej"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=860x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-62lsa9 --center bg--top pb--80 pt--80" name={"informacje-5"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left mt--80">
              
              <Title className="title-box title-box--center fs--86" content={"<span style='color: white'>Czekamy na Ciebie!</span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center" content={"<span style='color: white'>14.7.2021</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mb--80 mt--40" style={{"maxWidth":900}} columns={"1"}>
            
            <Text className="text-box text-box--style8 text-box--center" content={"<span style='color: white'><a href=\"https://saywebpage.com\">Tworzenie stron</a></span>"}>
            </Text>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}